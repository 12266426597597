<template>
  <HXContentCard title="Lista de competencias">
    <b-modal
      id="modal-competencia"
      title="Descripción competencia"
      size="md"
      hide-footer
    >
      <div class="text-center">
        <div class="row">
          <div class="col-12 text-left">
            <h4><strong> Nombre Competencia</strong></h4>
            <p class="mt-2">
              {{ row.titulo }}
            </p>
          </div>
          <div class="col-12 text-left mt-3">
            <h4><strong> Tipo Competencia </strong></h4>
            <p class="mt-2">
              {{ row.tipoCompetencia.descripcion }}
            </p>
          </div>
          <div class="col-12 text-left mt-3">
            <h4><strong> Descripción </strong></h4>
            <p class="mt-2">
              {{ row.descripcion }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="text-right px-4 mt-4">
      <div class="d-flex justify-content-end">
        <vs-button
          icon
          animation-type="vertical"
          class="mx-1 text-right"
          v-if="usuario.rol.nivel == 4"
          @click="$router.push('Competencias_agregar')"
        >
          <i class="fas fa-plus-circle px-1"></i>
          Agregar Competencia
          <template #animate>
            <i class="fas fa-plus-circle"></i>
          </template>
        </vs-button>
      </div>
    </div>
    <div class="table-responsive px-4 mt-3">
      <table
        id="datatable_areas"
        class="table table-bordered table-responsive-md table-striped text-center"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Título competencia</th>
            <th>Tipo de competencia</th>
            <th v-if="usuario.rol.nivel == 4">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in listaItems" :key="item.id">
            <td>
              <strong>
                {{ index + 1 }}
              </strong>
            </td>
            <td>{{ item.titulo }}</td>
            <td>{{ item.tipoCompetencia.descripcion }}</td>
            <td v-if="usuario.rol.nivel == 4" style="width: 150px !important">
              <ButtonGroup
                :dataModal="item"
                :Id="item.id"
                :data="dataIcons"
              ></ButtonGroup>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </HXContentCard>
</template>
<script>
import { core } from "../../../config/pluginInit";
import ButtonGroup from "../../../components/ButtonGroup/ButtonGroup.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    ButtonGroup,
  },
  data() {
    return {
      listaItems: [],
      logoEmpresa: "",
      usuario: {},
      row: {
        titulo: "",
        tipoCompetencia: {
          descripcion: "",
        },
        descripcion: "",
      },
      dataIcons: [
        {
          type: "success",
          tooltip: "Editar competencias",
          title: "",
          value: 10,
          iconName: "pen",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.$router.push("competencias_editar/" + Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          type: "primary",
          tooltip: "Ver descripción",
          title: "",
          value: 999,
          iconName: "eye",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",

          onActivate: (dataModal) => {
            console.log("Activated 999 -->", dataModal);
            // this.$bvModal.push()
            this.showModal(dataModal);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          type: "danger",
          tooltip: "Eliminar registro",
          title: "",
          value: () => 0,
          iconName: "trash",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: (Id) => {
            console.log("Activated");
            this.deleteRow(Id);
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.getData();
      core.index();
      window.$("#datatable_areas").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    showModal(item) {
      this.row = item;
      this.$bvModal.show("modal-competencia");
    },
    async deleteRow(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1A5CFF",
        cancelButtonColor: "rgb(242, 19, 67)",
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Competencia/Delete/" + id,
          });

          //console.log("res", res);
          //loader.hide()
          await this.getData();

          Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
        }
      });
    },
    async getData() {
      //console.log(this.persona.empresa.id);
      try {
        console.log("departamentoss.....................");
        let res = await this.$store.dispatch("hl_post", {
          path: "Competencia/ListCompetencia/",
        });
        console.log("post departamento... ", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style lang="scss"></style>
